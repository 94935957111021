






import { Vue, Component } from "vue-property-decorator";
import VesselExpiringDocumentsTable from "@/components/vessel/VesselExpiringDocumentsTable.vue";

@Component({ components: { VesselExpiringDocumentsTable } })
export default class ExpiringVesselDocumentsOverview extends Vue {}
