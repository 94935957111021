









































import { Vue, Component } from "vue-property-decorator";
import Api from "@/api";
import nameof from "@/utility/nameof";
import { getModule } from "vuex-module-decorators";
import { VesselDocumentModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";
import { DataTableHeader } from "vuetify";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselExpiringDocumentsTable extends Vue {
  private loading = false;
  private items: Array<VesselDocumentModel> = [];
  private headers: Array<DataTableHeader<VesselDocumentModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "FileName", value: nameof<VesselDocumentModel>("filename") },
    { text: "Vessel", value: nameof<VesselDocumentModel>("vesselName") },
    {
      text: "Reference",
      value: nameof<VesselDocumentModel>("reference")
    },
    {
      text: "Document Number",
      value: nameof<VesselDocumentModel>("documentNumber")
    },
    { text: "Start Date", value: nameof<VesselDocumentModel>("startDate") },
    { text: "End Date", value: nameof<VesselDocumentModel>("endDate") },
    {
      text: "Uploaded",
      value: nameof<VesselDocumentModel>("createdUtc")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getVesselDocuments();
  }

  private async getVesselDocuments() {
    try {
      this.loading = true;
      const response = await Api.VesselDocumentService.apiVesseldocumentExpiringGet();
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel documents");
    } finally {
      this.loading = false;
    }
  }

  private async onUpdateVesselDocument(document: VesselDocumentModel) {
    if (!document.vesselId) {
      return;
    }
    this.$router.push({
      name: "Vessel Document",
      params: { vesselId: document.vesselId }
    });
  }
}
